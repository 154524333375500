import React, { PureComponent } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { TimelineLite } from 'gsap';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Observer from 'react-intersection-observer';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import '../sass/_contact-us.scss';
import markerIcon from '../../static/img/contact_us/map-pointer.png';
import logo from '../../static/img/global/bprg-cms.png';
import * as globalAnimation from '../animations/globalAnimation';

class ContactUs extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateHeading(this.tl, '.title', '.subtitle');
    globalAnimation.AnimateEaseInOut(this.tl, '.animate-card-up');
  }

  animateSocial = InView => {
    const tl1 = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.fadeUp(tl1, '.animate-social-up');
      globalAnimation.fadeRight(tl1, '.animate-social-right');
      globalAnimation.fadeLeft(tl1, '.animate-social-left');
    }
  };

  render() {
    const {
      data: {
        markdownRemark: {
          frontmatter: { title, cards, contact },
        },
      },
    } = this.props;

    const { google } = this.props;
    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="Contact Us" />
        <Container fluid className="main_wrapper section-one">
          <Row>
            <Col>
              <div className="wrapper c-title-wrapper">
                <div className="page_heading_section text-center">
                  <h3 className="page_heading text-uppercase d-lg-block d-none title">{title}</h3>
                  <h4 className="text-uppercase page_heading_child subtitle">{title}</h4>
                </div>
              </div>
            </Col>
          </Row>
          <div className="wrapper contact-us-wrapper pt-lg-0">
            <Row className="animate-card-up">
              {cards.map(card => (
                <Col sm={12} md={4} className="my-3 my-lg-0">
                  <div className="office-card-wrapper">
                    <Card className="office-card">
                      <div className="image-scale-container">
                        <Img fluid={card.image.childImageSharp.fluid} />
                      </div>
                      <Card.Body>
                        <Card.Title>{card.title}</Card.Title>
                        {card.address.map(text => (
                          <Card.Text className="address mb-0">{text}</Card.Text>
                        ))}
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              ))}
            </Row>
            <Row>
              <Col sm={12} className="pt-lg-30">
                <div className="mt-map-container mt-3">
                  <div className="mt-map">
                    <Map
                      item
                      xs={12}
                      google={google}
                      zoom={3}
                      initialCenter={{ lat: 40.317362, lng: -74.629039 }}
                    >
                      <Marker
                        title="600 Alexander Road Suite 1-1D, Princeton NJ 08540"
                        position={{ lat: 40.327751, lng: -74.646346 }}
                        name="600 Alexander Road Suite 1-1D, Princeton NJ 08540"
                        animation={this.props.google.maps.Animation.DROP}
                        options={{
                          icon: markerIcon,
                        }}
                      />
                      <Marker
                        title="450 Seventh Avenue Suite 1604, New York, NY 10123"
                        position={{ lat: 40.751637, lng: -73.990675 }}
                        name="450 Seventh Avenue Suite 1604, New York, NY 10123"
                        animation={this.props.google.maps.Animation.DROP}
                        options={{
                          icon: markerIcon,
                        }}
                      />
                      <Marker
                        title="3 Waterhouse Square 138-142 Holborn, London EC1N 2SW"
                        position={{ lat: 51.518548, lng: -0.110232 }}
                        name="3 Waterhouse Square 138-142 Holborn, London EC1N 2SW"
                        animation={this.props.google.maps.Animation.DROP}
                        options={{
                          icon: markerIcon,
                        }}
                      />
                      <Marker
                        title="235 Montgomery Street, Suite 822 San Francisco, CA 94104"
                        position={{ lat: 37.791647, lng: -122.402869 }}
                        name="235 Montgomery Street, Suite 822 San Francisco, CA 94104"
                        animation={this.props.google.maps.Animation.DROP}
                        options={{
                          icon: markerIcon,
                        }}
                      />
                      <Marker
                        title="245 First Street Riverview II 18th Floor Cambridge, MA 02142"
                        position={{ lat: 42.363788, lng: -71.079094 }}
                        name="245 First Street Riverview II 18th Floor Cambridge, MA 02142"
                        animation={this.props.google.maps.Animation.DROP}
                        options={{
                          icon: markerIcon,
                        }}
                      />
                      <Marker
                        title="2400 Market Street, #235A Philadelphia, PA 19003"
                        position={{ lat: 39.953763, lng: -75.179436 }}
                        name="2400 Market Street, #235A Philadelphia, PA 19003"
                        animation={this.props.google.maps.Animation.DROP}
                        options={{
                          icon: markerIcon,
                        }}
                      />
                    </Map>
                  </div>
                </div>
              </Col>
              <Col xs={1} className="d-none">
                <img src={logo} className="h-auto w-100" alt="" />
              </Col>
            </Row>
            {/* <div className="office-map">
              
            </div> */}
            <Observer onChange={this.animateSocial} triggerOnce>
              <Row className="mt-lg-5 mt-3 justify-content-center">
                {contact.map(data => (
                  <Col xs={12} lg={4} className={`my-2 d-flex align-items-center ${data.colClass}`}>
                    <div className={`d-flex ${data.class}`}>
                      <div className="mr-3 d-lg-block d-flex justify-content-center align-items-center">
                        <img
                          className="d-none d-lg-block"
                          src={data.image.publicURL}
                          style={{ height: '52px', width: '52px' }}
                          alt=""
                        />
                        <img
                          className="d-lg-none"
                          src={data.image.publicURL}
                          style={{ height: '40px', width: '40px' }}
                          alt=""
                        />
                      </div>
                      <div className="align-self-center">
                        <p className="text-bold mb-0">{data.title}</p>
                        <a href={data.href} className="mb-0 text-muted">
                          {data.subtitle}
                        </a>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Observer>
          </div>
        </Container>
      </Layout>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCjxf5cE9P-DJ3sShePO_OUyL5GHZvwvjc',
})(ContactUs);

export const query = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "contact-us" } }) {
      frontmatter {
        title
        cards {
          title
          address
          class
          image {
            childImageSharp {
              fluid(maxWidth: 512, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        contact {
          title
          subtitle
          href
          image {
            publicURL
          }
          colClass
          class
        }
      }
    }
  }
`;
